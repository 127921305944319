<template>
    <div class="class-manage">
        <table-page-template
            v-bind="templateConfig"
            :paging-config.sync="getClassListParams"
            :total-count="totalCount"
            @page-change="getClassList"
        >
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="editClass(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="delClass(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog
            class="operate-class"
            :title="operateDialogTitle"
            :is-show.sync="operateDialog"
            :confirm-handler="confirmOperate"
            @closeDialog="clearFormData"
            padding="40px 0 40px"
        >
            <div class="form-box">
                <pack-el-form
                    ref="elForm"
                    :rules="rules"
                    :params.sync="operateParams"
                    :form-config="formConfig"
                    label-width="65"
                ></pack-el-form>
            </div>
        </my-dialog>
        <my-dialog class="delete-class" :title="`删除班级`" :is-show.sync="deleteDialog" :confirm-handler="confirmDelete">
            <div class="content">是否删除此班级, 删除后将无法恢复</div>
        </my-dialog>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";
import PackElForm from "components/common/PackElForm";
import MyDialog from "components/common/MyDialog";
import { operateClass, getClassListData, deleteClass } from "@/utils/apis";
export default {
    name: "Index",
    data() {
        return {
            tableData: [],
            getClassListParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
            },
            operateParams: {
                banji_name: "",
                banji_id: "",
            },
            rules: {
                banji_name: [
                    { required: true, message: "请输入班级名称", trigger: "blur" },
                    { min: 2, message: "班级名称的长度不能小于2", trigger: "blur" },
                ],
            },
            totalCount: 1,
            deleteId: -1,
            //dialog
            deleteDialog: false,
            operateDialog: false,
        };
    },
    computed: {
        operateDialogTitle() {
            return this.operateParams.banji_id ? "编辑班级" : "创建班级";
        },
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: `创建班级`,
                    handler: this.createClass,
                },
                tableConfig: [
                    { label: `班级名称`, prop: "banji_name" },
                    { label: "班级人数", prop: "student_num", align: "center" },
                    { label: "创建时间", prop: "create_time", align: "center" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
        formConfig() {
            return [
                {
                    label: "班级名称",
                    prop: "banji_name",
                    placeholder: "2-18个字符",
                    max: 18,
                },
            ];
        },
    },
    components: {
        TablePageTemplate,
        MyDialog,
        PackElForm,
    },
    methods: {
        init() {
            this.getClassList();
        },
        async getClassList() {
            const res = await getClassListData(this.getClassListParams);
            this.tableData = res.data.list;
            this.totalCount = res.data.total;
        },
        createClass() {
            this.operateDialog = true;
        },
        editClass(item) {
            Object.assign(this.operateParams, item);
            this.operateDialog = true;
        },
        async confirmOperate() {
            this.$refs.elForm.validate(async () => {
                let res = await operateClass(this.operateParams);
                this.$message.success(res.msg);
                this.operateDialog = false;
                this.getClassList();
            });
        },
        delClass(item) {
            this.deleteId = item.banji_id;
            this.deleteDialog = true;
        },
        async confirmDelete() {
            let res = await deleteClass({ banji_id: this.deleteId });
            this.$message.success(res.msg);

            this.getClassList();
            this.deleteDialog = false;
        },
        clearFormData() {
            this.operateParams.banji_name = "";
            this.operateParams.id = "";
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.class-manage {
    box-sizing: border-box;
    height: 100%;
    padding: 30px 30px 0;
}
</style>
